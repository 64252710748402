<!--
 * @ Author: Zelmi Greyling
 * @ Create Time: 2021-10-21 10:04:30
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2022-02-28 14:50:09
 * @ Description: Component displaying the public general profile reviews tab.
 -->

<template>
    <!-- Cards -->
    <v-row
        justify="center"
    >
        <v-col
            cols="12"
            v-if="profileReviews.length > 0"
        >
            <v-row
                justify="end"
                align="center"
                style="position: relative;"
            >
                <div
                    :style="$vuetify.breakpoint.mdAndUp ? 'position: absolute; left: 12px; width: calc(100% - 24px);' : 'width: 100%;'"
                    :class="$vuetify.breakpoint.mdAndUp ? '' : 'px-3 py-3'"
                    class="os-16-sb"
                    v-if="profileReviews.length > 0"
                    style="display: flex; align-items: center;"
                >
                    <span v-if="!$store.state.general.customBreakpoints.xxs">Average &nbsp;</span> Rating: {{ averageRating }}
                    <v-col
                        class="text-center py-1"
                        style="width: 20px; max-width: 20px;"
                        v-for="i in 5"
                        :key="i"
                    >
                        <v-icon
                            color="#FFA858"
                            size="20"
                            v-if="averageRating >= i"
                        >
                            {{ mdiStar }}
                        </v-icon>
                        <v-icon
                            color="#FFA858"
                            size="20"
                            v-else
                        >
                            {{ mdiStarOutline }}
                        </v-icon>
                    </v-col>
                </div>
                <v-col
                    :cols="$vuetify.breakpoint.sm ? '6' : $vuetify.breakpoint.xs ? '12' : '3'"
                >
                    <v-select
                        dense
                        outlined
                        color="#2E567A"
                        class="os-13-r"
                        background-color="white"
                        placeholder="Sort By"
                        hide-details
                        :items="sortingOptions"
                        v-model="selectedSortingOption"
                        return-object
                        item-text="name"
                        item-value="id"
                        label="Sort By"
                        @change="getProfileReviews"
                    />
                </v-col>
                <v-col
                    :cols="$vuetify.breakpoint.sm ? '6' : $vuetify.breakpoint.xs ? '12' : '3'"
                >
                    <v-text-field
                        dense
                        hide-details
                        outlined
                        color="#2E567A"
                        placeholder="Search..."
                        class="os-13-r"
                        background-color="white"
                        clearable
                        v-model="search"
                    >
                        <template v-slot:append>
                            <v-icon
                                color="#C5CEE0"
                                class="ma-0"
                            >
                                {{ mdiMagnify }}
                            </v-icon>
                        </template>
                    </v-text-field>
                </v-col>
            </v-row>
        </v-col>

        <!-- No Reviews -->
        <v-col
            v-if="profileReviews === null || profileReviews.length === 0"
            class="os-12-r text-center"
            style="color: rgba(0, 0, 0, 0.38);"
            cols="12"
        >
            No reviews received
        </v-col>
        <!-- No Reviews -->

        <!-- No Search Results -->
        <v-col
            v-else-if="filteredReviews === null || filteredReviews.length === 0"
            class="os-12-r text-center"
            style="color: rgba(0, 0, 0, 0.38);"
            cols="12"
        >
            No results found for "{{ search }}"
        </v-col>
        <!-- No Search Results -->

        <!-- Reviews -->
        <v-col
            cols="12"
            :style="$vuetify.breakpoint.mdAndUp ? 'max-width: 55%;' : ''"
            v-for="(item, index) in filteredReviews"
            :key="index"
            v-else
        >
            <v-card
                :height="item.expanded ? '' : '158px'"
                width="100%"
                max-width="550px"
                class="rounded-lg pa-3 mx-auto"
            >
                <v-row
                    style="position: relative;"
                    class="pb-6 pt-3"
                >
                    <!-- Reviewer's Avatar -->
                    <v-col
                        class="flex-shrink-1 flex-grow-0 pt-0"
                    >
                        <v-avatar
                            size="46"
                        >
                            <img
                                :src="item.reviewer_avatar ? item.reviewer_avatar : '/general/FixerIndividualIcon.png'"
                            >
                        </v-avatar>
                    </v-col>
                    <!-- Reviewer's Avatar -->

                    <!-- Reviewer's Name and Review Date -->
                    <v-col
                        class="flex-grow-1 flex-shrink-0 text-truncate os-16-sb pt-0"
                        align-self="center"
                    >
                        <div>{{ item.reviewer_name }}</div>
                        <div
                            class="os-13-r"
                            style="color: #8F9BB3;"
                        >
                            {{ item.reviewer_review_created_date }}
                        </div>
                    </v-col>
                    <!-- Reviewer's Name and Review Date -->

                    <v-col
                        cols="12"
                        class="py-0"
                    >
                        <v-divider />
                    </v-col>

                    <!-- Reviewer's Star Rating -->
                    <v-col
                        cols="12"
                        class="pb-0"
                    >
                        <v-row>
                            <v-col
                                class="text-center py-1"
                                style="width: 20px; max-width: 20px;"
                                v-for="i in 5"
                                :key="i"
                            >
                                <v-icon
                                    color="#FFA858"
                                    size="20"
                                    v-if="item.reviewer_rating >= i"
                                >
                                    {{ mdiStar }}
                                </v-icon>
                                <v-icon
                                    color="#FFA858"
                                    size="20"
                                    v-else
                                >
                                    {{ mdiStarOutline }}
                                </v-icon>
                            </v-col>
                        </v-row>
                    </v-col>
                    <!-- Reviewer's Star Rating -->

                    <!-- Job Name -->
                    <v-col
                        cols="12"
                        class="os-16-sb pt-2"
                        align-self="center"
                    >
                        <div
                            style="display: flex; align-items: end;"
                        >
                            <v-icon
                                color="#2E567A"
                                size="16"
                                v-if="item.work_type_id === 1"
                                class="mb-1"
                            >
                                {{ mdiFlashOutline }}
                            </v-icon>
                            <v-icon
                                color="#2E567A"
                                size="16"
                                v-else-if="item.work_type_id === 2"
                                class="mb-1"
                            >
                                {{ mdiAccountSupervisorOutline }}
                            </v-icon>
                            {{ item.work_name }}
                            <!-- <div style="max-width: calc(100% - 24px - 105px); display: inline-block;" class="text-truncate">
                                <v-icon
                                    color="#2E567A"
                                    size="16"
                                    v-if="item.work_type_id === 1"
                                >
                                    {{ mdiFlashOutline }}
                                </v-icon>
                                {{ item.work_name }}
                            </div>
                            <div @click="viewJob(item.work_id, item.reviewee_id)" class="os-13-r ml-1" style="cursor: pointer; display: inline-block;">(Click to view job)</div> -->
                        </div>

                        <v-btn
                            style="position: absolute; bottom: 4px; right: 4px;"
                            icon
                            @click="toggleExpand(item)"
                        >
                            <v-icon
                                v-if="!item.expanded"
                            >
                                {{ mdiChevronDown }}
                            </v-icon>
                            <v-icon
                                v-else
                            >
                                {{ mdiChevronUp }}
                            </v-icon>
                        </v-btn>
                    </v-col>
                    <!-- Job Name -->

                    <v-col
                        v-if="item.expanded"
                        cols="12"
                    >
                        <v-row>
                            <v-col
                                cols="12"
                                class="os-15-sb py-0"
                            >
                                Feedback from {{ item.reviewer_name }}:
                            </v-col>
                            <v-col
                                cols="12"
                                class="os-13-r pt-2"
                                style="color: #2E567A;"
                            >
                                "{{ item.reviewer_review }}"
                            </v-col>
                            <!-- <v-col
                                cols="12"
                                class="os-15-sb pb-0"
                            >
                                Feedback to {{ item.reviewer_name }}:
                            </v-col>
                            <v-col
                                class="text-center py-1"
                                style="width: 20px; max-width: 20px;"
                                v-for="i in 5"
                                :key="i"
                            >
                                <v-icon
                                    color="#8F9BB3"
                                    size="20"
                                    v-if="!item.reviewee_rating"
                                >
                                    {{ mdiStarOutline }}
                                </v-icon>
                                <v-icon
                                    color="#FFA858"
                                    size="20"
                                    v-else-if="item.reviewee_rating && item.reviewee_rating >= i"
                                >
                                    {{ mdiStar }}
                                </v-icon>
                                <v-icon
                                    color="#FFA858"
                                    size="20"
                                    v-else
                                >
                                    {{ mdiStarOutline }}
                                </v-icon>
                            </v-col>
                            <v-col
                                cols="12"
                                class="os-13-r pt-1"
                                style="color: #2E567A;"
                            >
                                <span v-if="item.reviewee_review">"{{ item.reviewee_review }}"</span>
                                <span style="color: #8F9BB3;" v-else>Feedback not submitted yet</span>
                            </v-col> -->
                        </v-row>
                    </v-col>
                </v-row>
            </v-card>
        </v-col>
        <!-- Reviews -->
    </v-row>
    <!-- Cards -->
</template>
<script>
    import { mdiStarOutline, mdiStar, mdiMagnify, mdiChevronDown, mdiChevronUp, mdiFlashOutline, mdiAccountSupervisorOutline } from '@mdi/js'
    import { GeneralReviewController } from '@/logic/controller/index.controller.js'

    const moment = require('moment')

    export default {
        name: 'ProfilePublicReviewsTabComponent',

        props: {
            companyUserID: {
                type: Number
            }
        },

        watch: {
            companyUserID: {
                async handler (value) {
                    if (value) await this.getProfileReviews(value)
                },
                deep: true
            }
        },

        computed: {
            filteredReviews () {
                if (this.search && this.search.replace(/\s/g, '') !== '') {
                    const self = this
                    const filteredItems = this.profileReviews.filter(function (e) {
                        return (e.work_name.toLowerCase().includes(self.search.toLowerCase()) || e.reviewer_name.toLowerCase().includes(self.search.toLowerCase()))
                    })

                    return filteredItems
                } else return this.profileReviews
            },

            averageRating () {
                return Number(this.profileReviews[0].reviewee_average_rating).toFixed(1)
            }
        },

        data () {
            return {
                mdiStarOutline,
                mdiStar,
                mdiMagnify,
                mdiChevronDown,
                mdiChevronUp,
                mdiFlashOutline,
                mdiAccountSupervisorOutline,
                profileReviews: [],
                search: '',
                generalReviewController: null,
                sortingOptions: [
                    {
                        id: 'newestDate',
                        name: 'Date: Newest first'
                    },
                    {
                        id: 'oldestDate',
                        name: 'Date: Oldest first'
                    },
                    {
                        id: 'highestRating',
                        name: 'Rating: Highest first'
                    },
                    {
                        id: 'lowestRating',
                        name: 'Rating: Lowest first'
                    }
                ],
                selectedSortingOption: {
                    id: 'newestDate',
                    name: 'Date: Newest first'
                }
            }
        },

        beforeMount () {
            this.generalReviewController = new GeneralReviewController()
        },

        async mounted () {
            if (this.$route.path.includes('individual')) await this.getProfileReviews(this.$route.params.id)
            else if (this.$route.path.includes('company') && this.companyUserID) this.getProfileReviews(this.companyUserID)
        },

        methods: {
            async getProfileReviews (id) {
                try {
                    this.profileReviews = await this.generalReviewController.retrieve(id, this.selectedSortingOption)

                    if (this.profileReviews && this.profileReviews.length > 0) {
                        for (let index = 0; index < this.profileReviews.length; index++) {
                            const review = this.profileReviews[index]
                            review.expanded = false

                            const dateTimeFormat = "YYYY-MM-DD HH:mm"
                            review.reviewer_review_created_date = moment(new Date(review.reviewer_review_created_date)).format(dateTimeFormat)

                            const removeTime = review.reviewer_review_created_date.split(' ')[0]
                            const time = review.reviewer_review_created_date.split(' ')[1]
                            const removeSeconds = time.split(':')
                            const convertDate = new Date(removeTime)
                            const splitConvertedDate = convertDate.toString().split(' ')
                            review.reviewer_review_created_date = splitConvertedDate[2] + ' ' + splitConvertedDate[1] + ' ' + splitConvertedDate[3] + ', ' + removeSeconds[0] + ':' + removeSeconds[1]
                        }
                    }
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            viewJob (jobID, applicantID) {
                this.$router.push(`/job/${jobID}/overview/${applicantID}`)
            },

            toggleExpand (item) {
                const reviewIndex = this.profileReviews.findIndex(itemToFind => itemToFind.reviewer_id === item.reviewer_id && itemToFind.reviewee_id === item.reviewee_id && itemToFind.work_id === item.work_id && itemToFind.work_type_id === item.work_type_id)
                if (reviewIndex > -1) {
                    this.profileReviews[reviewIndex].expanded = !this.profileReviews[reviewIndex].expanded
                    this.$forceUpdate()
                }
            }
        }
    }
</script>
<style scoped>
    >>> div.v-input__append-inner {
        margin-top: 5px !important;
    }
</style>
